<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Appointment Agents')" :description="$t('data_count', {count: pagination.totalRecords, type: $t('agents')})">
        <block-tools>
          <li>
            <nk-button type="primary" v-on:click="[resetAgentAddEditData(), addEditAgent.modalOpen=true]">
              <nio-icon icon="ni-plus"></nio-icon> {{$t('Add Agent')}}
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{ $t('Bulk Action') }}</option>
                        <option value="email">{{ $t('Send Email') }}</option>
                        <option value="suspend">{{ $t('Suspend') }}</option>
                        <option value="delete">{{ $t('Delete') }}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{ $t('Apply') }}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( statusFilter !== -1  ? 'primary':'')"
                            :title="$t('Agents')+' '+$t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="status_filter">{{ $t('Account status') }}</label>
                                <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                  <option value="-1">{{ $t('All') }}</option>
                                  <option value="1">{{ $t('Active')}}</option>
                                  <option value="0">{{ $t('Disabled') }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getAgents(true)">{{ $t('Filter') }}</nk-button>
                            <a href="javascript:;" v-on:click="[statusFilter='',getAgents(true)]">{{ $t('Reset Filter') }}</a>
                          </template>
                        </drop-down>
                      </li><!-- li -->
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getAgents(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getAgents(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search')+' '+$t('Agent')"
                  :is-active="searchBarActive"
                  @do-search="searchAgents"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchAgents('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="adv_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="sm">
                      <span class="sub-text">ID</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">{{ $t('Agent') }}</span>
                    </table-col>
                    <table-col break-point="mb">{{ $t('Address') }}</table-col>
                    <table-col break-point="md">{{$t('Leads')}}</table-col>
                    <table-col break-point="lg">{{ $t('Joined') }}</table-col>
                    <table-col break-point="lg">{{ $t('Last seen') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="agent in agents" :key="'ag_key'+agent.id">
                    <table-col has-check-box>
                      <b-check-box :id="'agent'+agent.id" v-model="agent.selected" :key="'ag_select'+agent.id"></b-check-box>
                    </table-col>
                    <table-col break-point="sm">{{ agent.id }}</table-col>
                    <table-col>
                      <user-small-card :user-name="agent.first_name+' '+agent.last_name" :user-email="agent.email" :image="agent.dp" :initials="agent.initials" :is-online="agent.is_online"></user-small-card>
                    </table-col>
                    <table-col break-point="mb">
                      <template v-if="agent.city">
                        <span class="tb-lead">{{ agent.city+' '+agent.post_code }}</span>
                        <span>{{agent.province }}</span>v
                      </template>
                    </table-col>
                    <table-col break-point="md">{{agent.leads}}</table-col>
                    <table-col break-point="lg">{{ agent.created_at }}</table-col>
                    <table-col break-point="lg">{{ agent.last_seen }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <table-action v-if="isDesktop"
                                      :icon="agent.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'" v-on:click="F_changeAccountStatus([agent])"></table-action>
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminApAgentProfile', params: {id: agent.id} }"></list-item>
                            <list-item icon="ni-activity-round" :text="$t('Activities')" :to="{name: 'advisorProfile', params: {id: agent.id, tab: 'activities'}}"></list-item>
                            <list-item icon="ni-opt-alt" :text="'Leads '+$t('Limit')" :to="{name: 'advisorProfile', params: {id: agent.id, tab: 'e_curring'}}"></list-item>
                            <list-item v-if="!isDesktop"
                                       :icon="agent.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'"
                                       :text="agent.user_status === 0 ? $t('Activate'): $t('Suspend')"
                                       v-on:click="F_changeAccountStatus([agent])"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal
        :is-open="addEditAgent.modalOpen"
        @didDismiss="addEditAgent.modalOpen=false"
        :css-class="isDesktop ? 'modal-web': ''">
      <i-modal :title="$t('Add/Edit Agent')" @modal-close="addEditAgent.modalOpen=false">
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="f_name">{{ $t('First name') }}</label>
              <b-input type="text" size="lg" id="f_name" placeholder="John" v-model="addEditAgent.userData.first_name"/>
            </column>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="l_name">{{ $t('Last name') }}</label>
              <b-input type="text" size="lg" id="l_name" placeholder="Doe" v-model="addEditAgent.userData.last_name"/>
            </column>
          </row>
        </form-group>
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="ap_email">{{ $t('Email') }}</label>
              <b-input type="text" size="lg" id="ap_email" v-model="addEditAgent.userData.email"/>
            </column>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="phone_no">{{ $t('Phone number') }}</label>
              <b-input type="text" size="lg" id="phone_no" v-model="addEditAgent.userData.phone_number"/>
            </column>
          </row>
        </form-group>
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <row>
                <column lg="6">
                  <label class="form-label" for="postcode">Postcode</label>
                  <b-input type="text" size="lg" id="postcode" placeholder="5542 AW" v-model="addEditAgent.userData.post_code"/>
                </column>
                <column lg="6">
                  <label class="form-label" for="house_no">{{ $t('House number') }}</label>
                  <b-input type="text" size="lg" id="house_no" v-model="addEditAgent.userData.house_no"/>
                </column>
              </row>
            </column>
            <column md="6">
              <label class="form-label" for="lang">{{$t('Language')}}</label>
              <b-select id="lang" v-model="addEditAgent.userData.lang" size="lg">
                <option value="nl">{{$t('Dutch')}}</option>
                <option value="en">{{$t('English')}}</option>
              </b-select>
            </column>
          </row>
        </form-group>
        <template v-slot:footer class="p-5">
          <nk-button type="primary" class="mr-2" v-on:click="addUpdateAgent()">{{ $t(addEditAgent.userData.id > 0 ? 'Update' : 'Add') }}</nk-button>
          <a href="javascript:;" class="link link-light" v-on:click="addEditAgent.modalOpen=false">{{ $t('Cancel') }}</a>
        </template>
      </i-modal>
    </ion-modal>
  </page-template>
</template>

<script>
import {
  IonModal,
  IonSpinner,
  alertController,
  loadingController,
  toastController,
  isPlatform, onIonViewWillEnter
} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import {BlockContent, BlockHead, BlockTools, Column, FormGroup, MainBlock, Row} from "@/@core/layouts";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import {DropDown, DropDownList} from "@core/components";

import Pagination from "v-pagination-3"
import {SpecialTable, TableAction, TableCol, TableRow} from "@core/components/special-table";
import {BCheckBox, BInput, BSelect} from "@core/components/bp-form";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import ListItem from "@core/components/dropdown-list/ListItem";

import {useRouter} from "vue-router"
import {useI18n} from "vue-i18n";
import IModal from "@core/components/ion-modal/IModal";
import useUser from "@core/comp-functions/User"
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    BSelect,
    BInput,
    FormGroup,
    IModal,
    BlockTools,
    ListItem,
    DropDownList,
    TableAction,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonModal,
    IonSpinner,
    Pagination,
  },
  setup() {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const router = useRouter()
    const{ changeAccountStatus, deleteUsers } = useUser()

    let searchFilter  = ref('');
    let statusFilter  = ref(-1)
    let order         = ref('desc')

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })

    let agents = ref([])
    let addEditAgent = reactive({
      modalOpen: false,
      userData:{
        email: '',
        first_name: '',
        house_no: '',
        id: 0,
        lang: '',
        last_name: '',
        phone_number: '',
        post_code: '',
      }
    });

    const resetAgentAddEditData = ()=>{
      addEditAgent.userData.email = ''
      addEditAgent.userData.first_name = ''
      addEditAgent.userData.house_no = ''
      addEditAgent.userData.id = 0
      addEditAgent.userData.lang = ''
      addEditAgent.userData.last_name  = ''
      addEditAgent.userData.phone_number  = ''
      addEditAgent.userData.post_code  = ''
    }

    const addUpdateAgent = async () => {

      let email_reg = /^\S+@\S+$/;
      if(!addEditAgent.userData.first_name.length || !addEditAgent.userData.last_name.length ){
        toastController.create({color: 'danger', message: i18n.t('registration.name_required'), duration:2000}).then( toast => toast.present())
        return
      }
      else if( !email_reg.test( addEditAgent.userData.email)){
        toastController.create({color: 'danger', message: i18n.t('registration.provide_valid_email'), duration:2000}).then( toast => toast.present())
        return
      }

      let loader = await loadingController.create({message: i18n.t('Please wait...')})
      await loader.present()

      const alertB = await alertController.create({
        header: i18n.t('Error'),
        cssClass: isDesktop ? 'alert-web' : '',
        buttons: [{text: i18n.t('OK'), cssClass: isDesktop ? 'alert-btn-light' : ''}]
      });

      axios.post('/api/admin/ap_agent/register_agent', addEditAgent.userData)
      .then(r=>{
        if(!r.data.success){
          alertB.message = r.data.message
          alertB.present()
        }
        else{
          addEditAgent.modalOpen = false
          let msg = i18n.t('thank_you_messages.ap_agent_added_updated')
          if(addEditAgent.userData.id < 1){
            msg += "<br>"+i18n.t('account_activation_mail_sent')
            agents.value.unshift(r.data.data)
          }
          else{
            for(let x in agents.value){
              if(parseInt(agents.value[x]) === parseInt(addEditAgent.userData.id)){
                agents.value[x] = r.data.data
              }
            }
          }
          alertController.create({
            header: i18n.t('Success'),
            cssClass: isDesktop ? 'alert-web' : '',
            message: msg,
            buttons:[{text: i18n.t('OK'), cssClass: isDesktop ? 'alert-btn-light' : ''}]
          })
          .then((al) => al.present())
          resetAgentAddEditData()
        }
      })
      .catch(er=>{
        alertB.message = er.response ? er.response.status+' '+er.response.statusText : i18n.t('errors.general_error')
        alertB.present()
      })
      .then(()=> loader.dismiss());
    }
    const editAgent = (index)=>{
      addEditAgent.userData  = Object.assign(addEditAgent.userData, agents[index])
      addEditAgent.modalOpen = true
    }

    const paginationCallback = async () => {
      await getAgents(true)
    }

    let cancelToken = false
    const getAgents = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/ap_agent/agents_list', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              agents.value = []
            }
            resp = resp.data
            for(let x in resp.data.records){
              resp.data.records[x].selected = false;
              agents.value.push(resp.data.records[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getAgents(true))

    const searchAgents = (search) => {
      searchFilter.value = search
    }

    onIonViewWillEnter(()=> getAgents(true))

    watch(selectAll, (n) => {
      for(let x in agents.value){
        agents.value[x].selected = n
      }
    })

    const F_changeAccountStatus = ( _advisors, action ) => {
      changeAccountStatus(_advisors, 'appointment agent', action)
          .then(res =>{
            for(let y in agents.value){
              if(res.ids.includes(agents.value[y].user_id)){
                agents.value[y].user_status = res.status
              }
            }
          }).catch(()=>{})
    }
    const F_deleteAgents = (users) => {
      deleteUsers(users, 'agent')
      .then(ids=>{
        // Remove the deleted entries from the advisors array
        for(let x in ids){
          for(let y in agents.value){
            if(agents.value[y].user_id === ids[x]){
              agents.value.splice(y,1)
              break;
            }
          }
        }
        // All deleted, no entry left in the advisor array? and previous pages exists
        if( !agents.value.length && pagination.page > 1 ){
          // Deduct from total records value
          pagination.totalRecords -= ids.length
          // Goto previous page
          pagination.page--
          getAgents(true)
        }
      }).catch(()=>{})
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedRecords = []
      for(let x in agents.value){
        if( agents.value[x].selected){
          selectedRecords.push( agents.value[x])
        }
      }
      if( selectedRecords.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'email' ){
        // TODO
        return false
      }
      else if( bulkSelectedAction.value === 'suspend' ){
        await F_changeAccountStatus(selectedRecords, 'suspend')
      }
      else{
        F_deleteAgents(selectedRecords, 'agent')
      }
    }

    return {
      addEditAgent,
      addUpdateAgent,
      agents,
      editAgent,
      getAgents,
      resetAgentAddEditData,

      isDesktop,
      loadingData,
      searchAgents,

      searchFilter,
      statusFilter,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      F_changeAccountStatus,

      bulkSelectedAction,
      bulkAction,

      router,
    }
  }
});

</script>
